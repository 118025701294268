@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* Correct @font-face rule with an absolute path */
@font-face {
  font-family: "Bird and Thorn";
  src: url("../public/fonts/BirdandThorn.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Custom styles */
.navbar-item {
  font-family: "Raleway", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.05em;
  position: relative;
  text-align: left;
  display: block;
  margin: 0;
  padding: 6px 12px;
  transition: all 0.2s;
  text-transform: uppercase;
  color: #9d646b;
}

.navbar-item:hover {
  color: #7a4a53;
}

.section::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #e1a291; /* Pink color for the line */
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0; /* Ensure it is behind the images */
}

.learn-more-btn:hover {
  background: #e1a291;
}

.font-bird-and-thorn {
  font-family: "Bird and Thorn", serif;
  font-weight: 300;
  color: #9d646b;
}

/* Base styles */
.yda-logo {
  position: relative;
  width: 60px;
  height: auto;
  z-index: 1;
  transition: position 0.4s ease, width 0.4s ease, height 0.4s ease;
}

/* Hamburger styling */
.hamburger {
  position: absolute;
  top: 30px;
  right: 20px;
  width: 30px;
  height: 22px;
  cursor: pointer;
  z-index: 30;
}

.hamburger__slice {
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #9d646b;
  left: 0;
  transition: all 0.4s ease-in-out;
}

.hamburger--slice-1 {
  top: 0;
}

.hamburger--slice-2,
.hamburger--slice-3 {
  top: 8px;
}

.hamburger--slice-4 {
  top: 16px;
}

.hamburger.open .hamburger--slice-1 {
  transform: rotate(45deg);
  top: 10px;
}

.hamburger.open .hamburger--slice-2,
.hamburger.open .hamburger--slice-3 {
  opacity: 0;
}

.hamburger.open .hamburger--slice-4 {
  transform: rotate(-45deg);
  top: 10px;
}

/* Menu container in mobile */
.menu-container {
  position: relative;
  width: 100%;
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: height 0.4s ease, opacity 0.4s ease;
}

.menu-container.open {
  height: 240px;
  opacity: 1;
}

/* List styling */
#navbar-default ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  transition: all 0.4s ease;
}

/* Desktop styles */
@media (min-width: 768px) {
  .menu-container {
    position: static;
    height: auto;
    opacity: 1;
  }

  #navbar-default ul {
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 0;
  }

  .hamburger {
    display: none;
  }
}

/* Slick Carousel Customization */
.slick-list {
  height: auto !important; /* Ensure dynamic height */
}

.slick-track {
  display: flex !important;
  align-items: center !important;
  height: auto !important; /* Ensure track height adjusts with content */
}

.slick-slide {
  display: flex !important;
  align-items: center !important; /* Center content vertically */
  justify-content: center !important;
  height: auto !important; /* Allow slides to be dynamic */
}

/* Slick Dots Customization */
.slick-dots {
  position: absolute;
  bottom: -25px; /* Adjusts dynamically based on the current height */
  list-style: none;
  display: flex; /* Flexbox for better alignment */
  justify-content: center; /* Center the dots */
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  display: inline-block; /* Inline dots */
  margin: 0 8px; /* Adjust spacing between the dots */
}

/* Hide default slick dot buttons */
.slick-dots li button::before {
  display: none;
}

/* Custom Font Awesome dot styling */
.slick-dots li button .slick-dot-icon {
  font-size: 16px;
  color: #d88f96;
  opacity: 0.5;
  transition: opacity 0.3s ease, color 0.3s ease;
}

/* Active dot */
.slick-dots li.slick-active button .slick-dot-icon {
  opacity: 1;
  color: #d88f96; /* Keep color consistent */
}

/* For hover effect (optional) */
.slick-dots li button:hover .slick-dot-icon {
  opacity: 0.8;
  color: #d88f96; /* Optional hover color change */
}

/* Mobile specific padding for the carousel text */
@media (max-width: 768px) {
  .slick-slide {
    padding-left: 20px;
    padding-right: 20px; /* Add space to the left and right of the text */
  }

  p {
    font-size: 1.125rem; /* Mobile font size */
    line-height: 1.75;
  }

  h2 {
    font-size: 2.5rem;
    line-height: 1.2;
  }

  h4 {
    font-size: 2rem;
  }
}

/* Add responsive styles for text adjustments */
@media (max-width: 768px) {
  h2 {
    font-size: 2.5rem; /* Adjust for mobile */
    line-height: 1.2; /* Ensure spacing is proportional */
  }
  h4 {
    font-size: 2rem; /* Adjust the subheading for mobile */
  }
  p {
    font-size: 1.125rem; /* Make paragraph text slightly smaller */
    line-height: 1.75; /* Increase spacing for better readability */
  }
  .slick-slide {
    padding: 20px 0; /* Add space to mobile slides */
  }
}

/* Spacing for desktop */
@media (min-width: 768px) {
  h2 {
    font-size: 3.5rem; /* Adjust header font size */
  }
  h4 {
    font-size: 3.5rem; /* Adjust subheading font size */
    line-height: 1.3;
  }
  p {
    font-size: 1.25rem;
    line-height: 1.85; /* Add extra line height for desktop */
  }
  .slick-slide {
    padding: 30px 0; /* Add more padding for large screens */
  }
}

/* About.css */

/* Hide background for mobile screens */
@media (max-width: 767px) {
  .about-section {
    background-image: none !important;
  }
}

/* Set the background for larger screens */
@media (min-width: 768px) {
  .about-section {
    background-image: "url('/Moon-and-circle.jpg')";
    background-position: right -28px top 80px;
    background-size: cover;
    background-attachment: fixed;
  }
}

@media (min-width: 768px) {
  .section-background {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media (max-width: 1024px) {
  .welcome-section {
    background-image: none !important;
  }
}

/* Override focus styles globally */
input:focus, textarea:focus, select:focus, .Mui-focused {
  outline: none !important; /* Important to override browser styles */
  box-shadow: 0 0 5px #d88f96 !important; /* Custom pink shadow */
  border-color: #d88f96 !important;
}
